import { ADVICE_TYPES } from 'src/utils/employer/adviceTypes';

export default {
    accountAddEmail: '/account/add_email',
    accountConnect: '/account/connect/(register|merge)',
    accountCaptcha: '/account/captcha',
    accountDelete: '/account/delete_me',
    accountDeleteAffirmation: '/account/delete_me/affirmation',
    accountDeleteConfirmation: '/account/delete_me/confirmation',
    accountDeleteGratitude: '/account/delete_me/gratitude',
    accountHistory: '/employer/account',
    accountHistoryExpenditures: '/employer/account/expenditures',
    accountHistoryMoney: '/employer/account/money',
    accountHistoryReplenishments: '/employer/account/replenishments',
    accountHistoryServices: '/employer/account/services',
    accountLogin: '(/account/login|/auth/applicant|/login|/logon.do)',
    accountPhoneVerification: '/account/phone_verification',
    advancedResumeSearch: '/search/resume/advanced',
    advancedResumeSearchVariants: '(/search/resume/advanced|/resumesearch)',
    advancedVacancySearch: '/search/vacancy/advanced',
    agencies: '/agencies',
    agenciesList: '/agencies/list',
    anonymousEmployer: '/employer',
    applicantApplications: '/applicant/applications',
    applicantBlacklistVacancy: '/applicant/blacklist/vacancy',
    applicantGallery: '/applicant/gallery',
    applicantBlacklistEmployers: '/applicant/blacklist/employer',
    applicantCountriesVisibility: '/applicant/settings/resume_countries_visibility',
    applicantNegotiations: '/applicant/negotiations',
    applicantPortrait: '/search/resume/portrait',
    applicantPhoneVerification: '/applicant/phone_verification',
    applicantResumes: '/applicant/resumes',
    applicantSkillVerificationsMethods: '/applicant/skill_verifications/methods',
    applicantRsvVerifications: '/applicant/rsv_verifications/:id(\\w+)',
    applicantResumesList: '/applicant/resumelist',
    applicantServicesPayment: '/applicant/services/payment',
    applicantSignup: '/account/signup',
    applicantSignupVerify: '/account/verify',
    applicantSuitableVacancies: '/applicant/resumes/suitable_vacancies',
    applicantVacancyResponsesSummaryPayment: '/applicant/vacancy_summary/payment',
    applicantVacancyResponsesSummarySuccess: '/applicant/vacancy_summary/success',
    applyAgreement: '/account/apply_agreement',
    appPromo: '/app_promo',
    article: [
        '/article/:articleId([a-zA-Z0-9_-]+)',
        '/article.(do|xml)',
        '/interview/:articleId([a-zA-Z0-9_-]+)',
        '/b2b',
        '/smb',
        '/price-list',
    ],
    authEmployer: '/auth/employer',
    authEmployerAlready: '/auth/employer/already',
    authEmployerDuplicated: '/auth/employer/duplicated',
    authEmployerSuccess: '/auth/employer/success',
    authLoginZarplata: '/auth/login',
    authRegistrationZarplata: '/auth/registration',
    away: '/away',
    autologin: '/autologin',
    brandingProxyService: '/branding',
    blog: '/articles',
    brandSnippetEditor: '/brand_snippet_editor',
    careerMarathonPayment: '/applicant/career_marathon/payment',
    careerMarathonSuccess: '/applicant/career_marathon/success',
    chat: [
        '/chat',
        '/chat/:chatId([a-zA-Z0-9_-]+)',
        '/chat/:chatId([a-zA-Z0-9_-]+)/participants',
        '/chat/:chatId([a-zA-Z0-9_-]+)/workflow/:state([a-zA-Z0-9_-]+)',
    ],
    employerApplications: '/employer/applications',
    employerAddresses: '/employer/addresses',
    employerAgreements: '/employer/agreements',
    employerAutomationInfo: '/employer/automation',
    employerAdvices: `/employer/advices/:adviceType(${ADVICE_TYPES.join('|')})`,
    employerCarts: '/employer/carts',
    employerUnpaidCarts: '/employer/unpaid_carts',
    employerSettings: '/employer/settings',
    employerMailTemplates: '/employer/settings/email',
    employerManagersCreate: '/employer/managers/create',
    employerNegotiationsChangeTopic: '/employer/negotiations/change_topic',
    employerConstructor: '/employer/constructor',
    employerConstructorWebview: '/employer/constructor/webview/:id(\\d+)',
    employerContactsVisibility: '/employer/contacts_visibility',
    employerDocuments: '/employer/documents',
    employerDocumentsManagement: '/employer/documents-management',
    employerDeeds: '/employer/deeds',
    employerFinancialDocuments: '/employer/financial-documents',
    employerVerificationActs: '/employer/verificationacts',
    employerMakeupWebView: '/employer/makeup/webview/:id(\\d+)',
    employerManagers: '/employer/managers',
    employerInvoicePaylink: '/employer/invoice/paylink',
    employerInvoicePayment: '/employer/invoice/payment',
    employerInvoicePurchase: '/employer/invoice/purchase',
    employerInvoiceFinish: '/employer/invoice/finish',
    employerQuota: '/employer/quota',
    employerQuotaHistory: '/employer/quota/history',
    employerReviewsSettings: '/employer/reviews-settings',
    employerSidebarEdit: '/employer/edit/sidebar',
    employerSimpleEdit: '/employer/edit/simple',
    employerStructure: '/employer/structure',
    employerReviews: '/employer_reviews',
    employersCompany: [
        '/employers_company',
        '/employers_company/:industryPath',
        '/employers_company/:industryPath/:subIndustryPath',
    ],
    employersList: '/employers_list',
    employersListZarplata: '/companies',
    employerStructureChangelog: '/employer/structure/changelog',
    employerSubscription: '/employer/settings/subscription',
    employerTests: '/employer/tests',
    employerTestsView: '/employer/tests/view',
    employerTestsConstructor: '/employer/tests/constructor',
    employerTestsLibrary: '/employer/tests/library',
    employerTestsLibraryCategory: '/employer/tests/library/:categoryId',
    employerSearchPublication: '/employer/settings/search-publication',
    employerUnpremoderatedVacancyList: '/employer/unpremoderatedvacancylist',
    employerVacancies: '/employer/vacancies',
    employerVacanciesArchived: '/employer/vacancies/archived',
    employerVacanciesDrafts: '/employer/vacancies/drafts',
    employerVacanciesFunnelGroups: '/employer/vacancies/funnel_groups',
    employerVacanciesTemplates: '/employer/vacancies/templates',
    employerVacanciesPromotion: '/employer/vacancies/:vacancyId(\\d+)/promotion',
    EmployerVacanciesAuctionWebview: '/employer/vacancies/:vacancyId(\\d+)/auction',
    employerVacanciesRise: '/employer/vacancies/rise',
    employerVacanciesVr: '/employer/vacancies/vr',
    employerView: '/employer/(edit|\\d+)(|/main|/mission|/career|/recruitment|/office|/address)',
    employerViewZarplata: '/companies/id(\\d+)(|/[a-zA-Z0-9_-]+)',
    employerQuotas: '/employer/settings/quotas',
    events: '/articles/events',
    eventsForStudents: '/articles/students/events-for-students',
    fastVacanciesSubscription: '/applicant/services/subscription',
    feedback: '/feedback',
    jobStatistics: '/employer/job_stats',
    jobStatisticsAdmin: '/employer/job-stats',
    indexPage: '/',
    invalidatePassword: '/account/invalidate_password',
    lockHistory: '/employer/lock_history',
    oauthCallback: '/oauth/callback',
    oauthAuthorize: '/oauth/authorize',
    oauthAuthorizeEmployer: '/oauth/authorize/employer',
    oauthMergeByCode: '/account/connect/merge_by_code',
    partTimeVacancies: '/vacancies/podrabotka',
    personalManager: '/employer/personal_manager',
    priceAddContacts: '/price/add-contacts',
    priceBranding: '/price/branding',
    priceClickmeProducts: '/price/clickme',
    priceDBAccess: '/price/dbaccess',
    priceEmployerBrand: '/price/employerbrand',
    priceRecommended: '/price/recommended',
    pricePayForPerformance: '/price/pay-for-performance',
    priceRegionalPublications: '/price/regional-publications',
    priceCrmSystem: '/price/talantixcrm',
    priceProfRolesPublications: '/price/profroles-publications',
    priceZpResumeAccess: '/price/zp-resume-access',
    priceZpPublications: '/price/zp-publications',
    priceZpBundles: '/price/zp-bundles',
    priceZpPromotion: '/price/zp-promotion',
    priceZpBackoffice: '/price/zp-price-backoffice',
    promotionUseSearchTop: '/employer/vacancies/promotion/use_search_top',
    promotionPayForContact: '/pay-for-contact-omsk',
    registrationZarplata: '/registration',
    rememberPassword: '/account/remember_password',
    restorePassword: '/account/restore_password',
    resumeBuilderAdditional: '/applicant/resumes/edit/additional',
    resumeBuilderContacts: '/applicant/resumes/edit/contacts',
    resumeBuilderCreds: '/applicant/resumes/edit/creds',
    skillsLevelsEditor: '/applicant/resumes/edit/skills_levels',
    resumeBuilderEducation: '/applicant/resumes/edit/education',
    resumeBuilderExperience: '/applicant/resumes/edit/experience',
    resumeBuilderPersonal: '/applicant/resumes/edit/personal',
    resumeBuilderPhoto: '/applicant/resumes/edit/photo',
    resumeBuilderPosition: '/applicant/resumes/edit/position',
    resumeCompletion: '/applicant/resumes/completion',
    resumeFolder: '/employer/resumefolders/folder',
    resumeFolders: '/employer/resumefolders',
    resumeFoldersSearch: '/employer/resumefolders/search',
    resumeSearch: '/search/resume',
    // resumeSearch используется для формирования урлов, поэтому создаем отдельно алиасы
    resumeSearchAliases: '/employer/similar/resumesimilar.mvc',
    resumeSearchCatalogListMain: '/resumes',
    resumeSearchCatalogListByLetter: '/resumes/:type(eng|rus|etc)/:char([a-z]{1,3})?/:page(page-\\d+)?',
    resumeSearchCatalog: '/resumes/:professionOrExtraFilter/:extraFilter?',
    resumeSearchCatalogRegion: '/region/resumes',
    resumeSavedSearch: '/resumesavedsearch',
    resumeView: '/applicant/resumes/view',
    resumeViewByID: '/resume/:id(\\w+)',
    resumeVerificationReport: '/resume/:id(\\w+)/verification',
    applicantSkillVerificationShortReport: '/applicant/verification_result/:token',
    applicantSkillVerificationReport: '/applicant/skills/:skill_id/:level_id/report',
    applicantKeyskillsVerificationMethods: '/applicant/:type(skills|langs)/:id(\\w+)/verification_methods',
    resumeVisibility: '/applicant/resumes/edit/visibility',
    salaryStat: '/search/resume/salary_stat',
    shortResume: '/applicant/resumes/short',
    applicantAddAuthPhone: '/applicant/add_auth_phone',
    specializedPartTimeVacancies: '/vacancies/:specialization/podrabotka',
    subscribe: '/subscribe',
    subscribeZarplata: '/subscriptions',
    support: '/support',
    targetAgenciesPayment: '/applicant/targetagencies/payment',
    targetEmployerResumes: '/employer/targetresumes',
    trustSource: '/account/trust_source',
    twoFactorArticle: '/account/login/two_factor_article',
    unsubscribe: '/unsubscribe/:unsubscribeStatus',
    vacanciesProbationCatalog: '/stazhirovki',
    vacanciesProbationCatalogProfarea: '/stazhirovki/:profarea',
    vacancyConstructor: '/employer/vacancyconstructor',
    videoVacancy: '/employer/video_vacancy',
    vacancyConstructorMobilePreview: '/employer/vacancyconstructor/template/(\\d+)/mobile',
    vacancyConstructorTemplatePreview: '/employer/vacancyconstructor/template/:templateId(\\d+)',
    vacancyCreate: '/employer/vacancy/create',
    vacancyEditPending: '/employer/vacancy/edit_pending',
    vacancyDuplicate: '/employer/vacancy/duplicate/:originalVacancyId',
    vacancyEdit: '/employer/vacancy/edit/:vacancyId',
    vacancyFavorite: '/applicant/favorite_vacancies',
    vacancyPreview: '/vacancy/preview',
    vacancyPreviewDraft: '/vacancy/preview_draft',
    vacancyPrint: '/vacancy/view',
    vacancyResponse: '/applicant/vacancy_response',
    VacancySavedSearch: '/applicant/autosearch(.xml)?',
    VacancySavedSearchUnsubscribe: '/vacancysavedsearch/unsubscribe',
    VacancySavedSearchConfirm: '/vacancysavedsearch/confirm',
    vacancyResponses: '/employer/vacancyresponses(.mvc)?',
    vacancyResponsesAutoAction: '/employer/vacancyresponses/autoaction',
    VacancyResponsesAutoInvite: '/employer/vacancyresponses/autoinvite',
    VacancyResponsesFunnel: '/employer/vacancyresponses/funnel',
    vacancyResponsesCalls: '/employer/vacancyresponses/calls(.mvc)?',
    vacancyResponsesOutcomingCalls: '/employer/vacancyresponses/outcoming_calls(.mvc)?',
    vacancyResponseTest: '/applicant/vacancy_response/test',
    vacancyRestore: '/employer/vacancy/restore/:archivedVacancyId',
    vacancySearch: '/search/vacancy',
    vacancySearchZarplata: '/vacancy:q(\\?q=.*)?',
    vacancySearchMap: '/search/vacancy/map',
    vacancyMetroMain: '/metro',
    vacancySearchMetroCatalog: '/metro/:line/:station?',
    vacancySearchCatalogListMain: '/vacancies',
    vacancySearchCatalogListByLetter: '/vacancies/:type(eng|rus|etc)/:char([a-z]{1,3})?/:page(page-\\d+)?',
    vacancySearchCatalog: '/vacancies/:professionOrExtraFilter/:extraFilter?',
    vacancySearchCatalogZarplata: '/vacancy/:professionOrExtraFilter([a-zA-Z0-9_-]+)/:extraFilter?',
    vacancySearchCatalogRegion: '/region/vacancies',
    vacancyTemplate: '/employer/vacancies/templates/template',
    vacancyView: '/vacancy/:id(\\d+)',
    employerVacancyView: '/employer/vacancy/:id(\\d+)',
    // Отдельное свойство, т.к. не получается сдружить именованный параметр с регуляркой "ИЛИ"
    vacancyViewAliases: '/vacancy.do',
    vrSignupLogin: '/vr_signup_login',
    wantWorkPayment: '/applicant/wantwork/payment',
    resumeProfile: '/profile',
    applicantServices: '/applicant-services',
    mentors: '/mentors',
    mailto: '/employer/mailto',
    messengerAuthCodeForward: '/messenger/auth/code_forward',
    vrsurvey: '/vrsurvey',
    services: '/services',
    resumeViewHistory: '/applicant/resumeview/history',
    employerDeedsOrder: '/employer/deed_order',
    applicantAssessment: '/applicant/assessment',
    employerHiringManagerVacancies: '/employer/hiring_manager/vacancies',
    employerHiringManagerArchivedVacancies: '/employer/hiring_manager/vacancies/archived',
    employerSuitablesUnsubscribe: '/employer/vacancy/suitables_unsubscribe',
    employerVacancyResponseTest: '/employer/vacancy_response/test',
    push2FACallback: '/account/login/push_2fa_callback',
};
